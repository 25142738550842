<template>
  <div>
    <cta-banner :title="$route.name"/>
    <cta-description class="md:pt-28" :descripcion="header" :patrocinar="true" v-if="header.titulo" />
    <section class="container mx-auto py-5" v-for="banner in banners" :key="banner.id" v-if="banners.length > 0">
      <cta-call-action :info="banner" :backend="true" :blank="true" v-if="banner.link.includes('http')"/>
      <cta-call-action :info="banner" :backend="true" :blank="false" v-else/>
    </section>

    <section class="container mx-auto py-5" :style="'height:' + form.icono" v-if="form.link">
        <iframe :src="form.link" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
    </section>
  </div>
</template>

<script>
  import API from "@/api";
  import CtaBanner from '../../components/common/CtaBanner.vue';
  import CtaDescription from '../../components/CtaDescription.vue';
  import CtaCallAction from '../../components/common/CtaCallAction.vue';
  import {mapState} from "vuex";

export default {
  name: "Pre-registro",
  components: {
    CtaBanner,
    CtaDescription,
    CtaCallAction
  },
  data: () => ({
    header: {},
    banners: [],
    form: {}
  }),
  created() {
    this.getInfo();
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  methods: {
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.header = (items[i].codigo === 'PR01') ? items[i] : this.header;
        this.form = (items[i].codigo === 'PR02') ? items[i] : this.form;
        if (items[i].codigo === 'PR03'){
          this.banners.push(items[i]);
        }
      }
    }
  }
}
</script>

<style>

</style>
